
import { Vue, Component } from "vue-property-decorator";
import { mdiFacebook, mdiTwitter } from "@mdi/js";

@Component
export default class SocialButtons extends Vue {
    twitterIcon: string = mdiTwitter;
    facebookIcon: string = mdiFacebook;
    twitterLink: string = process.env.VUE_APP_TWITTER_PAGE_URL as string;
    facebookLink: string = process.env.VUE_APP_FACEBOOK_PAGE_URL as string;
}
