export const DASHBOARD_CHART_IDS = {
    DSTV_TOTAL_ORDERS: "dstv-total-orders",
    DSTV_NEW_CUSTOMER_ORDERS: "dstv-new-customer-orders",
    AIRTIME_TOTAL_ORDERS: "airtime-total-orders",
    ENEO_PREPAY_TOTAL_ORDERS: "eneo-prepay-total-orders",
    ENEO_POSTPAY_TOTAL_ORDERS: "eneo-postpay-total-orders",
    EXCHANGE_TOTAL_ORDERS: "exchange-total-orders",
    CAMWATER_TOTAL_ORDERS: "camwater-total-orders",
    INCOMPLETE_ORDERS: "incomplete-orders",
    AIRTIME_NEW_CUSTOMER_ORDERS: "airtime-new-customer-orders",
    ENEO_PREPAY_NEW_CUSTOMER_ORDERS: "eneo-prepay-new-customer-orders",
    FAILED_PAYMENTS: "failed-payments",
    REFUNDED_PAYMENTS: "refunded-payment",
    CANAL_PLUS_TOTAL_ORDERS: "canal-plus-total-orders",
    DSTV_RECOVERED_ORDERS: "dstv-recovered-orders",
    TEST_ORDERS: "test-orders",
    TOTAL_PAYMENTS: "total-payments",
    TOTAL_EXPENSES: "total-expenses",
    TOTAL_PROFITS: "total-profits",
    WHATSAPP_ORDERS: "whatsapp-orders",
    REFERRAL_ORDERS: "referral-orders",
    CUSTOMER_RATINGS: "customer-ratings",
};
