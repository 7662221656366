// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import * as Sentry from "@sentry/vue";
import Vue from "vue";
import { Integrations } from "@sentry/tracing";
import { fetchFromLocalStorage } from "@/models/user";

Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN || undefined,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: [process.env.VUE_APP_SENTRY_TRACE_ORIGIN as string],
        }),
    ],
    Vue: Vue,
    environment: process.env.VUE_APP_ENV,
    release: "nyangapay-frontend@" + process.env.VUE_APP_COMMIT_HASH,
    // We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 0.7,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const captureSentryException = (exception: any) => {
    const user = fetchFromLocalStorage();
    if (user) {
        Sentry.setUser({ id: fetchFromLocalStorage()?.uid });
    } else {
        Sentry.setUser(null);
    }
    Sentry.captureException(exception);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const captureSentryMessage = (message: any) => {
    const user = fetchFromLocalStorage();
    if (user) {
        Sentry.setUser({ id: fetchFromLocalStorage()?.uid });
    } else {
        Sentry.setUser(null);
    }
    Sentry.captureMessage(JSON.stringify(message));
};

export default Sentry;
